@font-face {
  font-family: "GT-Walsheim";
  src: url("../assets/fonts/GT-Walsheim-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "GT-Walsheim";
  src: url("../assets/fonts/GT-Walsheim-Bold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "GT-Walsheim";
  src: url("../assets/fonts/GT-Walsheim-Regular.woff2") format("woff2");
  font-weight: 500;
}

/* Font Smoothing */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "GT-Walsheim", Roboto, "Helvetica Neue", Arial, sans-serif;
}
